import type { QuasarPluginOptions } from 'quasar'
import { Dark, LocalStorage, Notify, Quasar } from 'quasar'
import iconSet from 'quasar/icon-set/svg-eva-icons.js'
import type { UserModule } from '~/types'

// import lang from 'quasar/lang/en-US'
// import 'quasar/src/css/index.sass';
// import '@quasar/extras/eva-icons/eva-icons.css';
// import '@quasar/extras/roboto-font/roboto-font.css';

export const install: UserModule = (ctx) => {
  ctx.app.use(Quasar, {
    config: {
      ripple: false,
      dark: isDark.value,
    },
    plugins: {
      Dark,
      Notify,
      LocalStorage,
    },
    iconSet,
  } as QuasarPluginOptions,
  // @ts-expect-error - ssrContext is valid here
  {
    req: {
      url: ctx.router.currentRoute.value.path,
      headers: {},
    },
  })
}
